import { Modal } from "antd";
import DOMPurify from "dompurify"; // For secure HTML sanitization
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const HtmlModal = ({ visible, onClose, htmlFile, item, lang }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null); // Store error message
  const [fetchedHtml, setFetchedHtml] = useState(null);
  const iframeRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    setError(null); // Reset error on new fetch
    console.log("html modal info.", visible, onClose, htmlFile, item, lang);
    const fetchHTML = async () => {
      try {
        let combinedHtml = "";
        // ... (Fetch logic similar to original)
        if (htmlFile) {
          if (htmlFile.length > 1) {
            const htmlContents = await Promise.all(
              htmlFile.map(async (fileName) => {
                const response = await fetch(
                  `/${lang === "fr" ? "datahtmllois" : "englaw"}/${fileName}`
                );
                if (!response.ok) {
                  throw new Error(`Failed to fetch ${fileName}`);
                }
                return await response.text();
              })
            );
            combinedHtml = htmlContents.join("");
          } else {
            const response = await fetch(
              `/${lang === "fr" ? "datahtmllois" : "englaw"}/${htmlFile[0]}`
            );
            combinedHtml = await response.text();
          }
        }
        const sanitizedHtml = DOMPurify.sanitize(combinedHtml); // Sanitize fetched HTML
        setFetchedHtml(sanitizedHtml); // Update fetched content
      } catch (error) {
        console.error("Error fetching HTML files:", error);
        setError(error.message); // Set user-friendly error message
      } finally {
        setLoading(false);
      }
    };

    fetchHTML();
  }, [htmlFile]);

  function stringToArray(str) {
    if (Array.isArray(str)) {
      return str;
    }

    if (str.startsWith("[") && str.endsWith("]")) {
      const arr = str.slice(1, -1).split(",");

      const formattedArr = arr.map((item) =>
        item.trim().replace(/^'(.*)'$/, "$1")
      );

      return formattedArr;
    }

    if (str.startsWith("")) {
      return str.split(",");
    }

    throw new Error("Invalid input format");
  }

  const highlightElements = () => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      const contentWindow = iframeRef.current.contentWindow;
      const iframeDocument = contentWindow.document;

      console.log("highlighting ...///", item);

      const outputArray = stringToArray(item.hyperlink);

      console.log("highlighting after format", outputArray);

      if (Array.isArray(outputArray)) {
        let elementToScroll = null;

        for (let i = 0; i < outputArray.length; i++) {
          const element = outputArray[i];
          const targetElement = iframeDocument.getElementById(element);
          console.log("targetElement///", targetElement);

          if (targetElement) {
            targetElement.style.backgroundColor = "yellow";

            if (element !== null && element !== "None") {
              elementToScroll = targetElement;
              break;
            }
          }
        }

        if (elementToScroll) {
          elementToScroll.scrollIntoView();
        }
      } else {
        const targetElement = iframeDocument.getElementById(outputArray);

        if (targetElement) {
          targetElement.style.backgroundColor = "yellow";
          targetElement.scrollIntoView();
        }
      }
    }
  };

  return (
    <Modal
      open={visible}
      title={t("articleTitle")}
      onCancel={onClose}
      footer={null}
      width="90%"
      style={{
        maxWidth: "90vw",
        maxHeight: "90vh",
        margin: "5vh auto",
        padding: 0,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        overflow: "hidden",
      }}
      bodyStyle={{
        height: "calc(100% - 55px)", // Adjust based on your Modal's header height
        overflow: "auto",
      }}
    >
      {loading ? (
        <p>{t("loading")}</p>
      ) : error ? (
        <p>{t("error", { error })}</p>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              width: "90%",
              height: "90%",
              position: "relative",
              aspectRatio: "2 / 1",
            }}
          >
            <iframe
              title="Article Viewer"
              ref={iframeRef}
              srcDoc={fetchedHtml}
              onLoad={highlightElements}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                border: "none",
                boxShadow: "0 0 10px rgba(0,0,0,0.1)",
              }}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default HtmlModal;
