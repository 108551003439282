import {
  ApiOutlined,
  ArrowLeftOutlined,
  BgColorsOutlined,
  FileTextOutlined,
  RobotOutlined,
  WalletOutlined,
  UsergroupAddOutlined
} from "@ant-design/icons";
import { Card, Col, Layout, Row, Typography , Image} from "antd";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AppearancePage from "../components/AppearancePage";
import DocumentList from "../components/DocumentList";
import DocumentModelList from "../components/DocumentModelList";
import FilterList from "../components/FilterList";
import ModelList from "../components/ModelList";
import KnowledgeTable from "../components/KnowledgeTable";
import UserTable from "../components/UserList";

const { Content } = Layout;
const { Title } = Typography;

const Settings = () => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(null);

  const renderSelectedOption = () => {
    const component = (() => {
      switch (selectedOption) {
        case "appearance":
          return <AppearancePage />;
        case "documentlist":
          return <DocumentList />;
        case "modellist":
          return <ModelList />;
        case "documentmodellist":
          return <DocumentModelList />;
        case "filters":
          return <FilterList />;
        // case "Knowledge":
        //   return <KnowledgeTable />;
        //   case "user":
        //     return <UserTable />;
        default:
          return null;
      }
    })();
    return <Card>{component}</Card>;
  };

  const bentoBoxes = [
    {
      key: "appearance",
      icon: <BgColorsOutlined style={iconStyle} />,
      title: t('appearance'),
    },
    {
      key: "documentlist",
      icon: <FileTextOutlined style={iconStyle} />,
      title: t('documents'),
    },
    {
      key: "modellist",
      icon: <RobotOutlined style={iconStyle} />,
      title: t('models'),
    },
    {
      key: "documentmodellist",
      icon: <ApiOutlined style={iconStyle} />,
      title: t('documentModel'),
    },
    {
      key: "filters",
      icon: <ApiOutlined style={iconStyle} />,
      title: t('filters'),
    },
    // {
    //   key: "Knowledge",
    //   icon: <WalletOutlined style={iconStyle} />,
    //   title: "Knowledge",
    // },
    // {
    //   key: "user",
    //   icon: <UsergroupAddOutlined style={iconStyle} />,
    //   title: "User management",
    // },
  ];

  return (
    <Layout style={layoutStyle}>
                <Col flex="0 1 auto">
            <Link to="/">
              <Image
                src="/logo/logo.png"
                alt="MFI Logo"
                preview={false}
                style={{
                  height: "50px",
                  width: "auto",
                  marginTop: "10px",
                }}
              />
            </Link>
          </Col>
      <Content style={contentStyle}>
        {selectedOption ? (
          <div style={{ width: "100%", padding: "20px" }}>
            <ArrowLeftOutlined
              onClick={() => setSelectedOption(null)}
              style={{
                fontSize: "24px",
                cursor: "pointer",
                marginBottom: "20px",
              }}
            />
            {renderSelectedOption()}
          </div>
        ) : (
          <Row gutter={[16, 16]} style={rowStyle}>
            {bentoBoxes.map((box) => (
              <Col key={box.key} xs={24} sm={12} md={8} lg={6}>
                <Card
                  hoverable
                  style={cardStyle}
                  onClick={() => setSelectedOption(box.key)}
                >
                  {box.icon}
                  <Title level={4}>{box.title}</Title>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </Content>
    </Layout>
  );
};

// Styles
const layoutStyle = {
  minHeight: "100vh",
  padding: "20px",
};

const contentStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
  height: "100%",
};

const rowStyle = {
  width: "100%",
  maxWidth: "1200px",
  margin: "0 auto",
};

const cardStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  height: "150px",
};

const iconStyle = {
  fontSize: "36px",
  marginBottom: "10px",
};

export default Settings;
