import React, { useState, useEffect } from "react";
import { Table, Typography, Layout, Button, Row, Col, Modal, Form, Input, Select, InputNumber } from "antd";
import { EyeOutlined, DeleteOutlined, EditOutlined, PlusOutlined, StopOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
// import axiosInstance from "../axiosInstance";
// import TextArea from "antd/es/input/TextArea";

const { Title } = Typography;
const { Content } = Layout;
const { Option } = Select;

const UserTable = () => {
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const [total, setTotal] = useState(0);

  // Example users
  const exampleUsers = [
    { key: 1, name: "John Doe", email: "john@example.com", role: "Admin", dateJoined: "2023-01-01" },
    { key: 2, name: "Jane Smith", email: "jane@example.com", role: "User", dateJoined: "2023-02-15" },
    { key: 3, name: "Bob Johnson", email: "bob@example.com", role: "User", dateJoined: "2023-03-10" },
    { key: 4, name: "Alice Brown", email: "alice@example.com", role: "Moderator", dateJoined: "2023-04-20" },
    { key: 5, name: "John Doe", email: "john@example.com", role: "Admin", dateJoined: "2023-01-01" },
    { key: 6, name: "Jane Smith", email: "jane@example.com", role: "User", dateJoined: "2023-02-15" },
    { key: 7, name: "Bob Johnson", email: "bob@example.com", role: "User", dateJoined: "2023-03-10" },
    { key: 8, name: "Alice Brown", email: "alice@example.com", role: "Moderator", dateJoined: "2023-04-20" },
  ];

  useEffect(() => {
    setDataSource(exampleUsers);
    setTotal(exampleUsers.length);
  }, []);

  const paginatedData = dataSource.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  // Handle row selection
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  // Handlers for action buttons
  const handleEdit = (record) => {
    console.log('Edit', record);
    // Implement edit functionality here
  };

  const handleShow = (record) => {
    setSelectedRecord(record);
    setVisible(true);
  };

  const handleDelete = (key) => {
    console.log('Delete', key);
    // Implement delete functionality here
  };

  const handleBulkDelete = () => {
    console.log('Bulk Delete', selectedRowKeys);
    // Implement bulk delete functionality here
  };

  const handleBlockUsers = () => {
    console.log('Block Users', selectedRowKeys);
    // Implement block users functionality here
  };

  const handleClose = () => {
    setVisible(false);
    setSelectedRecord(null);
  };

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Handle page size change
  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1); // Reset to first page when page size changes
  };

  // Configure rowSelection
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // Define equal width for columns
  const columnWidth = 150; // Adjust this value as needed

  // All columns configuration for the table
  const allColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: columnWidth,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: columnWidth,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: columnWidth,
    },
    {
      title: 'Date Joined',
      dataIndex: 'dateJoined',
      key: 'dateJoined',
      width: columnWidth,
    },
    {
      title: 'Action',
      key: 'action',
      width: columnWidth,
      render: (text, record) => (
        <Button.Group>
          <Button onClick={() => handleShow(record)}>
            <EyeOutlined />
            Show
          </Button>
          <Button onClick={() => handleEdit(record)}>
            <EditOutlined />
            Edit
          </Button>
          <Button danger onClick={() => handleDelete(record.key)}>
            <DeleteOutlined />
            Delete
          </Button>
        </Button.Group>
      ),
    },
  ];

  return (
    <Content style={{ padding: '24px' }}>
      <Title level={2}>User Management</Title>
      <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
        <Col>
          <Button type="primary" ghost onClick={handleBulkDelete}>
            <PlusOutlined /> Add User
          </Button>
        </Col>
        {selectedRowKeys.length >= 2 && (
          <Col>
            <Button onClick={handleBlockUsers} style={{ marginRight: '8px' }}>
              <StopOutlined /> Block Users
            </Button>
            <Button danger onClick={handleBulkDelete}>
              <DeleteOutlined /> Bulk Delete
            </Button>
          </Col>
        )}
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Table
            rowSelection={rowSelection}
            dataSource={paginatedData}
            columns={allColumns}
            pagination={false} // Disable built-in pagination
            scroll={{ x: 'max-content' }}
          />
        </Col>
      </Row>
      <Row justify="start" align="middle" style={{ marginTop: '16px' }}>
        <Col>
          <Button
            onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
            disabled={currentPage === 1}
            icon={<LeftOutlined />}
          />
        </Col>
        <Col style={{ padding: '0 8px' }}>
          <InputNumber
            min={1}
            max={Math.ceil(total / pageSize)}
            value={currentPage}
            onChange={handlePageChange}
            style={{ width: 60, textAlign: 'center' }}
          />
        </Col>
        <Col>
          <Button
            onClick={() => handlePageChange(Math.min(currentPage + 1, Math.ceil(total / pageSize)))}
            disabled={currentPage === Math.ceil(total / pageSize)}
            icon={<RightOutlined />}
          />
        </Col>
        <Col style={{ marginLeft: '16px' }}>
          <Select value={pageSize} onChange={handlePageSizeChange} style={{ width: 120 }}>
            <Option value={6}>6 / page</Option>
            <Option value={12}>12 / page</Option>
            <Option value={24}>24 / page</Option>
          </Select>
        </Col>
      </Row>
      <Modal
        title={<div style={{ marginBottom: '30px' }}>View User Details</div>} // Add marginBottom here
        open={visible}
        onCancel={handleClose}
        footer={null}
        width={800} // Make the modal bigger
      >
        {selectedRecord && (
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Name">
                  <Input value={selectedRecord.name} readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Email">
                  <Input value={selectedRecord.email} readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Role">
                  <Input value={selectedRecord.role} readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Date Joined">
                  <Input value={selectedRecord.dateJoined} readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Button onClick={handleClose} style={{ marginTop: '10px' }}>Cancel</Button>
          </Form>
        )}
      </Modal>
    </Content>
  );
};

export default UserTable;
