import React, { Suspense } from "react";
import { I18nextProvider } from "react-i18next"; // Import the I18nextProvider from react-i18next
import i18n from "../i18n"; // Import the i18n instance
import MainLayout from "../layouts/MainLayout";

const MainRoutes = ({ children }) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <I18nextProvider i18n={i18n}>
        <MainLayout>{children}</MainLayout>
      </I18nextProvider>
    </Suspense>
  );
};
export default MainRoutes;
