import React, { useState, useEffect } from "react";
import { Table, Typography, Layout, Button, Row, Col, Modal, Form, Input } from "antd";
import { EyeOutlined, DeleteOutlined, EditOutlined , PlusOutlined } from "@ant-design/icons";
import axiosInstance from "../axiosInstance";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";

const { Title } = Typography;
const { Content } = Layout;

const KnowledgeTable = () => {
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const [total, setTotal] = useState(0);

  // Fetch data from the API
  const getData = async (page, perPage) => {
    try {
      const response = await axiosInstance.post("/qdrant/get_records", {
        page: 1,
        per_page: 300,
      });

      if (response.status === 200 && response.data) {
        const data = response.data.map((item, index) => ({
          key: index, // Use unique id for key if available
          date: moment(item.payload.date).format("YYYY-MM-DD"), // Format date here
          document: item.payload.document,
          langue: item.payload.langue,
          question: item.payload.question,
          question_original: item.payload.question_original,
          reponse: item.payload.reponse,
        }));
        setDataSource(data);
        setTotal(response.data.total); // Assuming the API returns the total number of records
      } else {
        console.error("Invalid API response format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Fetch data when component mounts or when pagination changes
  useEffect(() => {
    getData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  // Handle row selection
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  // Handlers for action buttons
  const handleEdit = (record) => {
    console.log('Edit', record);
    // Implement edit functionality here
  };

  const handleShow = (record) => {
    setSelectedRecord(record);
    setVisible(true);
  };

  const handleDelete = (key) => {
    console.log('Delete', key);
    // Implement delete functionality here
  };

  const handleBulkDelete = () => {
    console.log('Bulk Delete', selectedRowKeys);
    // Implement bulk delete functionality here
  };

  const handleClose = () => {
    setVisible(false);
    setSelectedRecord(null);
  };

  // Configure rowSelection
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // Define equal width for columns
  const columnWidth = 100; // Adjust this value as needed

  // All columns configuration for the table
  const allColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: columnWidth,
    },
    {
      title: 'Document',
      dataIndex: 'document',
      key: 'document',
      width: columnWidth,
    },
    {
      title: 'Langue',
      dataIndex: 'langue',
      key: 'langue',
      width: columnWidth,
    },
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
      width: columnWidth,
    },
    {
      title: 'Réponse',
      dataIndex: 'reponse',
      key: 'reponse',
      width: columnWidth,
    },
    {
      title: 'Action',
      key: 'action',
      width: columnWidth,
      render: (text, record) => (
        <Button.Group>
          <Button onClick={() => handleShow(record)}>
            <EyeOutlined />
            Show
          </Button>
          <Button onClick={() => handleEdit(record)}>
            <EditOutlined />
            Edit
          </Button>
          <Button danger onClick={() => handleDelete(record.key)}>
            <DeleteOutlined />
            Delete
          </Button>
        </Button.Group>
      ),
    },
  ];

  // Handle page change
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  return (
    <Content style={{ padding: '24px' }}>
      <Title level={2}>Base de connaissance</Title>
      <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
        <Col>
          <Button type="primary" ghost onClick={handleBulkDelete}>
          <PlusOutlined /> Ajoutez une question
          </Button>
        </Col>
        
          {selectedRowKeys.length >= 2 && (
            <Col>
            <Button type="primary" ghost onClick={handleBulkDelete}>
            <PlusOutlined /> blocker
            </Button>
            <Button danger onClick={handleBulkDelete}>
              <DeleteOutlined /> Bulk Delete
            </Button>
            </Col>
          )}
        
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Table
            rowSelection={rowSelection}
            dataSource={dataSource}
            columns={allColumns}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: total, // Ensure total is set
              showSizeChanger: true,
              pageSizeOptions: ['6', '12', '24'],
            }}
            onChange={handleTableChange}
            scroll={{ x: 'max-content' }}
          />
        </Col>
      </Row>
      <Modal
        title={<div style={{ marginBottom: '30px' }}>View User Details</div>} // Add marginBottom here
        open={visible}
        onCancel={handleClose}
        footer={null}
        width={800} // Make the modal bigger
      >
        {selectedRecord && (
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Date">
                  <Input value={selectedRecord.date} readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Document">
                  <Input value={selectedRecord.document} readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Langue">
                  <Input value={selectedRecord.langue} readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Réponse">
                  <Input value={selectedRecord.reponse} readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Question">
                  <TextArea value={selectedRecord.question} readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Button onClick={handleClose} style={{ marginTop: '10px' }}>Cancel</Button>
          </Form>
        )}
      </Modal>
    </Content>
  );
};

export default KnowledgeTable;
