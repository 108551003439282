import { Alert, Card, Col, Divider, Image, Layout, Row, Skeleton } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import axiosInstance from "../axiosInstance";
import ResultsComponent from "../components/ResultsComponent";
import SearchComponent from "../components/SearchComponent";
import i18n from "../i18n"; // Ensure correct path to your i18n config
import customContexts from "../services/customContexts";

const { Content } = Layout;

const Searchengine = () => {
  const { t } = useTranslation();
  const { authenticated, setLang, Lang } = useContext(customContexts);
  const [searchResults, setSearchResults] = useState([]);
  const [displayBoard, setDisplayBoard] = useState(false);

  const [similarquesiton, setSimilarquestion] = useState(null);
  const [fromhomequery, setFromhomequery] = useState(null);

  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const initialSearchPerformed = useRef(false);
  const topRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsHeaderFixed(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get("query");
    const lang = searchParams.get("lang");
    const selectedDocument = searchParams.get("document");
    const selectedModel = searchParams.get("model");
    const filteredFiltersString = searchParams.get("filters");
    const groupBy = searchParams.get("groupBy");

    if (query && !initialSearchPerformed.current) {
      console.log("starting search...");
      setFromhomequery(query);
      handleSearch(
        query,
        parseInt(selectedDocument),
        parseInt(selectedModel),
        filteredFiltersString,
        groupBy
      );
      initialSearchPerformed.current = true;
    }

    setLang(lang || "eng");
    i18n.changeLanguage(lang || "eng");
  }, [location.search]);
  // Scroll to the top whenever searchResults changes
  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [searchResults]);

  const handleSearch = async (
    referralCode,
    selectedDocument,
    selectedModel,
    filteredFiltersString,
    groupBy
  ) => {
    initialSearchPerformed.current = false;

    const searchParams = new URLSearchParams(location.search);
    const lang = searchParams.get("lang");
    setSearchResults([]);
    setNotFound(false);
    setLoading(true);
    console.log(
      "referralCode:",
      referralCode,
      "selectedDocument:",
      selectedDocument,
      "selectedModel:",
      selectedModel,
      "filteredFiltersString:",
      filteredFiltersString,
      "groupBy:",
      groupBy
    );

    const combinedResults = [];

    if (similarquesiton !== null) {
      referralCode = similarquesiton;
    }
    try {
      let chatgroupedResponse, historyResponse;

      try {
        historyResponse = await axiosInstance.post("/history", {
          query: referralCode,
          langue: lang,
        });
        if (historyResponse.status === 200) {
          combinedResults.push({
            source: "history",
            data: historyResponse.data,
          });
        }
      } catch (error) {
        console.error("Erreur dans l'API history:", error);
      }

      try {
        chatgroupedResponse = await axiosInstance.post("/chatgrouped", {
          query: referralCode,
          user: authenticated,
          selectedDocument: selectedDocument,
          selectedModel: selectedModel,
          filteredFiltersString: filteredFiltersString,
          langue: lang ? lang : Lang,
        });
        if (chatgroupedResponse.status === 200) {
          combinedResults.push({
            source: "chatgrouped",
            data: chatgroupedResponse.data,
          });
        }
      } catch (error) {
        console.error("Erreur dans l'API chatgrouped:", error);
      }
      console.log("Données retournées:", combinedResults);
      setSearchResults(combinedResults);
      setDisplayBoard(true);
      setNotFound(false);
    } catch (error) {
      console.error("Erreur dans handleSearch:", error);
      setNotFound(true);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Content style={{ overflow: "initial" }}>
      <div ref={topRef}>
        <Row
          gutter={[16, 16]}
          align="middle"
          justify="space-between"
          style={{
            marginLeft: isHeaderFixed ? "0px" : "10px",
            marginBottom: "16px",
            position: isHeaderFixed ? "fixed" : "static",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            backgroundColor: isHeaderFixed ? "white" : "transparent",
            boxShadow: isHeaderFixed ? "0 2px 8px rgba(0,0,0,0.1)" : "none",
            transition: "all 0.3s ease",
            padding: isHeaderFixed ? "10px 24px" : 0,
          }}
        >
          <Col flex="0 1 auto">
            <Link to="/">
              <Image
                src="/logo/logo.png"
                alt="MFI Logo"
                preview={false}
                style={{
                  height: "50px",
                  width: "auto",
                }}
              />
            </Link>
          </Col>
          <Col
            flex="1 1 auto"
            style={{ marginTop: "10px", maxWidth: "calc(100% - 100px)" }}
          >
            <SearchComponent
              onSearch={handleSearch}
              loading={loading}
              fromhomequery={fromhomequery}
              setFromhomequery={setFromhomequery}
              lang={"eng"}
            />
          </Col>
          <Col flex="0 0 40px"> </Col>
        </Row>

        <div style={{ marginTop: isHeaderFixed ? "80px" : 0 }}>
          {notFound && (
            <Alert
              message={t("visitorPage.noResults")}
              type="warning"
              showIcon
              style={{ maxWidth: "70%", width: "100%" }}
            />
          )}

          {loading && (
            <>
              <Divider />
              {[...Array(5)].map((_, rowIndex) => (
                <Row
                  key={rowIndex}
                  gutter={[16, 16]}
                  style={{ marginBottom: "16px" }}
                >
                  {[...Array(3)].map((_, colIndex) => (
                    <Col key={`${rowIndex}-${colIndex}`} xs={24} sm={12} md={8}>
                      <Card>
                        <Skeleton active />
                      </Card>
                    </Col>
                  ))}
                </Row>
              ))}
            </>
          )}

          {displayBoard && (
            <ResultsComponent results={searchResults} lang={"eng"} />
          )}
        </div>
      </div>
    </Content>
  );
};

export default Searchengine;
