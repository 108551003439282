// axiosInstance.js
import { JSON_API } from "./services/Constants";

import axios from "axios";
function logout() {
  localStorage.clear();
  window.location.reload();
}
const axiosInstance = axios.create({
  baseURL: JSON_API, // Replace with your API base URL
});

axiosInstance.interceptors.request.use(
  (config) => {
    // Modify the request config here (e.g., add headers, authentication tokens)
    const accessToken = localStorage.getItem("token");

    // ** If token is present add it to request's Authorization Header
    if (accessToken) {
      return config;
      // if (config.headers) config.headers.token = localStorage.getItem("token");
    }
    // return config;
  },
  (error) => {
    // Handle request errors here

    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Modify the response data here (e.g., parse, transform)

    return response;
  },
  (error) => {
    // Handle response errors here
    console.log("interceptor error:", error);
    if (error.response && error.response.status === 401) {
      // Handle 401 Unauthorized here, e.g., trigger a logout function
      logout();
    }
    // return Promise.reject(error);
  }
);

export default axiosInstance;
