import { Button, Col, ColorPicker, Form, Row } from "antd";
import React, { useContext } from "react";
import customContexts from "../services/customContexts";
const AppearancePage = () => {
  const { customColors, colorchosen, setAppColors, appColors } =
    useContext(customContexts);

  return (
    <div>
      <h1>Appearance Settings</h1>
      <Button type="primary">Button</Button>

      <Form
        layout="vertical"
        onValuesChange={(_, allValues) => {
          setAppColors({
            ...allValues,
          });
        }}
        name="theme"
        initialValues={appColors}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name={["Button", "colorPrimary"]}
              label="Primary Color"
              trigger="onChangeComplete"
              getValueFromEvent={(color) => color.toHexString()}
            >
              <ColorPicker />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AppearancePage;
