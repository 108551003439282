import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Layout, Select, message } from "antd";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import i18n from "../i18n";
import customContexts from "../services/customContexts";

const { Content } = Layout;

const StyledContent = styled(Content)`
  background: #212741;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: -20px 0 50px;
  font-family: "Montserrat", sans-serif;
`;
const LanguageSelector = styled(Select)`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 120px;
`;

const Container = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 900px;
  max-width: 100%;
  min-height: 480px;
  display: flex;
`;

const FormContainer = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
  left: 0;
  width: 50%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledForm = styled(Form)`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
  width: 100%;

  .ant-form-item {
    width: 100%;
    max-width: 300px;
  }

  .ant-input-affix-wrapper {
    height: 45px;
    display: flex;
    align-items: center;
    .ant-input {
      text-align: left; /* Center text horizontally */
    }
  }

  .ant-btn {
    height: 40px;
    width: 100%;
    max-width: 300px;
  }
`;

const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0; /* Adjust position to right */
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 1; /* Lower z-index to ensure it stays behind the form */
`;

const Overlay = styled.div`
  background: #ff416c;
  background: -webkit-linear-gradient(to right, #ff4b2b, #ff416c);
  background: linear-gradient(to right, #ff4b2b, #ff416c);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  height: 100%;
  width: 100%;
  transition: transform 0.6s ease-in-out;
`;

const OverlayPanel = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  text-align: center;
  top: 0;
  height: 100%;
  width: 100%;
  transition: transform 0.6s ease-in-out;
`;

const Title = styled.h1`
  font-weight: bold;
  margin: 0 0 20px 0;
  color: ${(props) => (props.white ? "#ffffff" : "#333")};
`;

const Paragraph = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
  color: #ffffff;
  max-width: 80%;
`;

const StyledButton = styled(Button)`
  border-radius: 20px;
  border: 1px solid #212741;
  background-color: #212741;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;

  &:active {
    transform: scale(0.95);
  }

  &:focus {
    outline: none;
  }
`;

const LoginPage = () => {
  const { customColors } = useContext(customContexts);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { authenticated, setAuthenticated, setLang, Lang } =
    useContext(customContexts);

  const changeLanguage = (value) => {
    setLang(value);
    i18n.changeLanguage(value);
    localStorage.setItem("lang", value);
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // const userinfo = {
      //   email: values.username,
      //   password: values.password,
      // };

      // Replace JSON_API with your actual API endpoint
      // const response = await axios.post(`${JSON_API}/auth/login`, userinfo);

      if (values.username === "jack" && values.password === "password") {
        localStorage.setItem("token", values.username);
        message.success("Connexion réussie");
        navigate("/");
      } else if (values.username === "user" && values.password === "password") {
        localStorage.setItem("token", values.username);
        message.success("Connexion réussie");
        navigate("/");
      } else if (
        values.username === "user1" &&
        values.password === "password"
      ) {
        localStorage.setItem("token", values.username);
        message.success("Connexion réussie");
        navigate("/");
      } else if (
        values.username === "benoit" &&
        values.password === "password"
      ) {
        localStorage.setItem("token", values.username);
        message.success("Connexion réussie");
        navigate("/");
      } else if (
        values.username === "yazid" &&
        values.password === "password"
      ) {
        localStorage.setItem("token", values.username);
        message.success("Connexion réussie");
        navigate("/");
      } else if (
        values.username === "ayman" &&
        values.password === "password"
      ) {
        localStorage.setItem("token", values.username);
        message.success("Connexion réussie");
        navigate("/");
      } else if (values.username === "omar" && values.password === "password") {
        localStorage.setItem("token", values.username);
        message.success("Connexion réussie");
        navigate("/");
      } else {
        message.error("Nom d'utilisateur ou mot de passe incorrect");
      }
    } catch (error) {
      console.error("Erreur de connexion:", error);
      message.error("Impossible de se connecter au serveur");
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledContent>
      <LanguageSelector
        defaultValue={i18n.language}
        onChange={changeLanguage}
        dropdownStyle={{ zIndex: 2000 }}
      >
        <Select.Option value="fr">Français</Select.Option>
        <Select.Option value="eng">English</Select.Option>
      </LanguageSelector>
      <Container>
        <FormContainer>
          <StyledForm name="login" onFinish={onFinish}>
            <img
              src="/logo/logo.png"
              alt="MFI AI Logo"
              style={{ width: "150px", height: "90px", marginBottom: "20px" }}
            />
            <Title>{t("login")}</Title>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: t("pleaseEnterUsername"),
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder={t("username")}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: t("pleaseEnterPassword"),
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder={t("password")}
              />
            </Form.Item>
            <Form.Item>
              <StyledButton type="primary" htmlType="submit" loading={loading}>
                {t("login")}
              </StyledButton>
            </Form.Item>
          </StyledForm>
        </FormContainer>
        <OverlayContainer>
          <Overlay>
            <OverlayPanel>
              <Title white>{t("myIntelligentAccountant")}</Title>
              <Paragraph>
                {t("optimizeYourFinances")} <em>{t("with")}</em> <em>AI</em>
              </Paragraph>
            </OverlayPanel>
          </Overlay>
        </OverlayContainer>
      </Container>
    </StyledContent>
  );
};

export default LoginPage;
