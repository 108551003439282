import {
  BookOutlined,
  ClockCircleOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Empty,
  Input,
  Layout,
  List,
  Modal,
  Row,
  Skeleton,
  Space,
  Steps,
  Typography,
} from "antd";
import { parse } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import "flag-icons/css/flag-icons.min.css";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import i18n from "../i18n";
import customContexts from "../services/customContexts";

const { Content } = Layout;
const { Search } = Input;
const { Title, Text } = Typography;
const { Step } = Steps;

const HomePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [query, setQuery] = useState(null);
  const [faqItems, setFaqItems] = useState([]);
  const [searchHistory, setSearchHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsContent, setStepsContent] = useState([]);

  const { customColors, authenticated, setLang, Lang } =
    useContext(customContexts);

  useEffect(() => {
    const storedLang = localStorage.getItem("lang");
    if (storedLang) {
      setLang(storedLang);
      i18n.changeLanguage(storedLang);
    }
  }, [setLang]);

  const handleLanguageChange = (value) => {
    setLang(value);
    i18n.changeLanguage(value);
    localStorage.setItem("lang", value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch(query);
    }
  };

  const handlefaqKeyPress = (e) => {
    if (e.key === "Enter") {
      handleFaqSearch(query);
    }
  };

  const handleFaqSearch = async (query) => {
    const obj = {
      query: query,
      langue: Lang,
    };

    try {
      setFaqItems([]);
      setLoading(true);
      const response = await axiosInstance.post("/generateQuestions", obj);

      if (response.data) {
        setFaqItems(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching Questions:", error);
      setLoading(false);
    }
  };

  const handleSearch = async (value) => {
    if (authenticated) {
      try {
        const response = await axiosInstance.get("/document/documents/active");
        if (response.data.documents.length) {
          response.data.documents.forEach((doc) => {
            if (Lang === "eng") {
              if (doc.type.toLowerCase().includes("canada,eng")) {
                navigate(
                  `/searchen?query=${encodeURIComponent(
                    value
                  )}&lang=${Lang}&document=${doc.id}`
                );
              }
            } else {
              if (doc.type.toLowerCase().includes("canada,fr")) {
                navigate(
                  `/searchfr?query=${encodeURIComponent(
                    value
                  )}&lang=${Lang}&document=${doc.id}`
                );
              }
            }
          });
        }
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    } else {
      navigate("/login");
    }
  };

  // const searchHistory = [
  //   { query: "Optimisation fiscale", date: "12 juillet 2024, 14:32" },
  //   { query: "Déductions fiscales 2024", date: "10 juillet 2024, 10:18" },
  //   // Add more history items as needed
  // ];

  // const faqItems = [
  //   {
  //     question:
  //       '"Déclarations erronées", "redressements", "comptes en désordre"... les défis fiscaux avant la saison des impôts.',
  //     answer:
  //       "La fiscalité suscite des préoccupations. Les erreurs de déclaration et les nouvelles régulations fiscales sont au centre des discussions...",
  //   },
  //   // Add more FAQ items as needed
  // ];

  // const tutorials = [
  //   "Comment rechercher sur MFI",
  //   "Optimiser votre recherche pour un resultat pertinant",
  //   "Comprendre votre espace administrative",
  // ];

  // const usefulLinks = [
  //   {
  //     url: "https://jurisprudence.service.canada.ca/search/index.html",
  //     title: "Jurisprudence Canada",
  //   },
  //   { url: "https://www.canlii.org/en/", title: "CanLII" },
  //   {
  //     url: "https://laws-lois.justice.gc.ca/fra/",
  //     title: "Justice Laws Website",
  //   },
  // ];

  const fetchPopularQuestions = async (referralCode) => {
    try {
      const generateQuestionsResponse = await axiosInstance.post(
        "/generateQuestions",
        {
          query: "popular",
          langue: Lang,
        }
      );
      if (generateQuestionsResponse.status === 200) {
        setFaqItems(generateQuestionsResponse.data);
      }
    } catch (error) {
      console.error(t("visitorPage.error.history"), error);
    }
  };

  useEffect(() => {
    fetchPopularQuestions();
  }, [Lang]);

  const formatQuebecTime = (dateString) => {
    console.log("time:", dateString);
    const quebecTimeZone = "America/Toronto"; // Quebec uses the same time zone as Toronto

    // Parse the input date string
    const parsedDate = parse(dateString, "dd/MM/yyyy | HH:mm", new Date());

    // Format the date in Quebec time
    return formatInTimeZone(parsedDate, quebecTimeZone, "dd/MM/yyyy | HH:mm");
  };

  const HistoryQuestions = async () => {
    try {
      const generateHistoryResponse = await axiosInstance.get(
        `/question/users/${authenticated}/questions/pagination`,
        {
          params: {
            page: 1,
            per_page: 6,
            langue: Lang,
          },
        }
      );
      if (generateHistoryResponse.status === 200) {
        const convertedHistory = (
          generateHistoryResponse.data.questions || []
        ).map((item) => ({
          ...item,
          date: formatQuebecTime(item.date),
          // date: item.date,
        }));
        setSearchHistory(convertedHistory);
      }
    } catch (error) {
      console.error(t("visitorPage.error.history"), error);
    }
  };

  const FAQSkeleton = () => (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <div style={{ width: "100%", maxWidth: "1000px" }}>
        {[1, 2, 3].map((item) => (
          <div key={item} style={{ marginBottom: 24 }}>
            <Skeleton active title={{ width: "70%" }} paragraph={{ rows: 1 }} />
          </div>
        ))}
      </div>
    </div>
  );

  useEffect(() => {
    if (authenticated) {
      HistoryQuestions();
    }
  }, [authenticated, Lang]);

  const handleFaqClick = (question) => {
    setQuery(question);
    handleSearch(question);
  };

  const handleFaqButtonshowmore = () => {
    navigate("/faq");
  };

  const showModal = (content) => {
    if (Array.isArray(content)) {
      setStepsContent(content);
    } else {
      setStepsContent([]);
    }
    setModalVisible(true);
  };

  const handleOk = () => {
    setModalVisible(false);
    setCurrentStep(0);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setCurrentStep(0);
  };

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <>
      <Row align="middle" style={{ padding: "5px 24px" }}>
        <Col span={3}>
          <img src="/logo/logo.png" alt="MFI Logo" style={{ height: "50px" }} />
        </Col>
        <Col span={17}>
          <Input
            prefix={<SearchOutlined />}
            placeholder={t("homePage.searchPlaceholder")}
            onKeyDown={handleKeyPress}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            size="large"
            style={{
              lineHeight: "28px",
              padding: "0 1rem",
              // border: `1px solid ${customColors.royalBlue.primary}`,
              borderRadius: "8px",
              outline: "none",
              backgroundColor: "#ffffff",
              color: "#0d0c22",
              transition: ".3s ease",
              width: "100%",
              maxWidth: "1000px",
              margin: "0 auto",
            }}
            allowClear
          />
        </Col>
        {/* <Col span={3}>
          <Row align="middle">
            <Col>
              <Select
                defaultValue={i18n.language}
                style={{
                  width: 130,
                  backgroundColor: "#ffffff",
                  borderRadius: "8px",
                  outline: "none",
                }}
                onChange={handleLanguageChange}
              >
                <Option value="fr">
                  <span
                    className="fi fi-fr"
                    style={{ fontSize: "20px", marginRight: "8px" }}
                  ></span>
                  {t("french")}
                  {Lang === "fr"}
                </Option>
                <Option value="eng">
                  <span
                    className="fi fi-ca"
                    style={{ fontSize: "20px", marginRight: "8px" }}
                  ></span>
                  {t("english")}
                  {Lang === "eng"}
                </Option>
              </Select>
            </Col>
          </Row>
        </Col> */}
      </Row>
      <Content style={{ padding: "24px" }}>
        <Row gutter={[20, 20]} style={{ marginTop: 0 }}>
          <Col xs={24} md={12}>
            <Row gutter={[20, 20]} style={{ marginTop: 0 }}>
              {authenticated && (
                <Col span={24}>
                  <Card style={{ height: "100%", paddingTop: "0px" }}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Title level={4} style={{ marginTop: "0px" }}>
                        {t("homePage.historyTitle")}
                      </Title>
                      <List
                        dataSource={searchHistory}
                        locale={{
                          emptyText: <Empty description={t("noData")} />,
                        }}
                        renderItem={(item) => (
                          <List.Item>
                            <Space>
                              <ClockCircleOutlined />
                              <Text>{item.texte}</Text>
                            </Space>
                            <Text type="secondary">{item.date}</Text>
                          </List.Item>
                        )}
                      />
                    </Space>
                  </Card>
                </Col>
              )}
              <Col xs={24} sm={24}>
                <Card style={{ height: "100%", paddingTop: "10px" }}>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Title level={4} style={{ marginTop: "0px" }}>
                      <BookOutlined /> {t("homePage.tutorialsTitle")}
                    </Title>
                    <List
                      dataSource={t("homePage.tutorials", {
                        returnObjects: true,
                      })}
                      locale={{
                        emptyText: <Empty description={t("noData")} />,
                      }}
                      renderItem={(item, index) => (
                        <List.Item
                          onClick={() => {
                            // if (
                            //   index !==
                            //   t("homePage.tutorials", { returnObjects: true })
                            //     .length -
                            //     1
                            // ) {
                            showModal(item.steps);
                            // }
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <Space>
                            <Text>{item.title}</Text>
                            <QuestionCircleOutlined />
                          </Space>
                        </List.Item>
                      )}
                    />
                  </Space>
                </Card>
              </Col>
              {/* <Col xs={24} sm={12}>
                <Card style={{ height: "100%", paddingTop: "10px" }}>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Title level={4} style={{ marginTop: "0px" }}>
                      <LinkOutlined /> {t("homePage.usefulLinksTitle")}
                    </Title>
                    <List
                      dataSource={t("homePage.usefulLinks", {
                        returnObjects: true,
                      })}
                      locale={{
                        emptyText: <Empty description={t("noData")} />,
                      }}
                      renderItem={(item) => (
                        <List.Item>
                          <a
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.title}
                          </a>
                        </List.Item>
                      )}
                    />
                  </Space>
                </Card>
              </Col> */}
            </Row>
          </Col>
          <Col xs={24} md={12}>
            {/* <Card style={{ height: "100%", paddingTop: "10px" }}> */}
            <Card>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Title level={4} style={{ marginTop: "0px" }}>
                  {t("homePage.faqTitle")}
                </Title>
                <Input
                  prefix={<SearchOutlined />}
                  placeholder={t("faqSearch")}
                  onChange={(e) => {
                    setQuery(e.target.value);
                  }}
                  onKeyDown={handlefaqKeyPress}
                />
                {loading ? (
                  <FAQSkeleton />
                ) : (
                  <List
                    dataSource={faqItems}
                    locale={{
                      emptyText: <Empty description={t("noData")} />,
                    }}
                    renderItem={(item) => (
                      <List.Item>
                        <Row style={{ width: "100%" }}>
                          <Col span={18}>
                            <a onClick={() => handleFaqClick(item.question)}>
                              {item.question}
                            </a>
                          </Col>
                          <Col span={6}>
                            <Text>{item.reponse}</Text>
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                  />
                )}
                <Button
                  type="primary"
                  onClick={handleFaqButtonshowmore}
                  style={{
                    backgroundColor: "#F83E29",
                    borderColor: "white",
                    color: "white",
                  }}
                  block
                >
                  {t("homePage.seeMoreButton")}
                </Button>
              </Space>
            </Card>
          </Col>
        </Row>
      </Content>
      <Modal
        title={t("homePage.tutorialsTitle")}
        open={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        footer={[
          <Button key="back" onClick={prev} disabled={currentStep === 0}>
            {t("Previous")}
          </Button>,
          <Button
            key="next"
            type="primary"
            onClick={next}
            disabled={currentStep === stepsContent.length - 1}
          >
            {t("Next")}
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            {t("Done")}
          </Button>,
        ]}
      >
        <Steps current={currentStep}>
          {stepsContent.map((_, index) => (
            <Step key={index} title={`${t("step")} ${index + 1}`} />
          ))}
        </Steps>
        <div className="steps-content">
          <div>
            <Text></Text>
            <br />
            <Text>{stepsContent[currentStep]?.text}</Text>
            <br />
            {stepsContent[currentStep]?.image && (
              <img
                src={stepsContent[currentStep].image}
                alt={`Step ${currentStep + 1}`}
                style={{
                  width: "100%",
                  maxWidth: "600px",
                  marginTop: "16px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HomePage;
