import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import axiosInstance from "../axiosInstance";
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { Title } = Typography;

const DocumentList = () => {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState([]);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingDocument, setEditingDocument] = useState(null);

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    try {
      const response = await axiosInstance.get("/document/documents");
      setDocuments(response.data.documents);
    } catch (error) {
      console.error(t("visitorPage.error.handleSearch"), error);
    }
  };

  const showModal = () => setVisible(true);

  const handleCancel = () => {
    setVisible(false);
    setEditingDocument(null);
    form.resetFields();
  };

  const handleCreate = async () => {
    try {
      const values = await form.validateFields();
      values.date = dayjs(values.date).format("YYYY-MM-DD");
      values.status = values.status.toString();

      await axiosInstance.post("/document/documents", values);
      message.success(t("visitorPage.successMessage"));

      fetchDocuments();
      form.resetFields();
      setVisible(false);
    } catch (error) {
      message.error(t("visitorPage.error.formSubmission"));
      console.error(t("visitorPage.error.formSubmission"), error);
    }
  };

  const handleEdit = (document) => {
    const { date, ...formData } = document;
    const formattedDate = dayjs(date);
    setEditingDocument({ ...formData, date: formattedDate });
    form.setFieldsValue({ ...formData, date: formattedDate });
    setVisible(true);
  };

  const handleUpdate = async () => {
    try {
      const values = await form.validateFields();
      values.date = dayjs(values.date).format("YYYY-MM-DD");
      values.status = values.status.toString();

      await axiosInstance.put(
        `/document/documents/${editingDocument.id}`,
        values
      );
      message.success(t("visitorPage.successMessage"));
      fetchDocuments();
      form.resetFields();
      setVisible(false);
    } catch (error) {
      console.error(t("visitorPage.error.formSubmission"), error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/document/documents/${id}`);
      message.success(t("visitorPage.successMessage"));
      fetchDocuments();
    } catch (error) {
      console.error(t("visitorPage.error.formSubmission"), error);
    }
  };

  return (
    <div>
      <Button onClick={showModal}>
        <PlusOutlined /> {t("addDocument")}
      </Button>
      <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
        {documents.map((document) => (
          <Col key={document.id} xs={24} sm={12} md={8} lg={6}>
            <Card
              hoverable
              actions={[
                <EditOutlined onClick={() => handleEdit(document)} />,
                <DeleteOutlined onClick={() => handleDelete(document.id)} />,
              ]}
            >
              <Card.Meta
                title={document.name}
                description={
                  <>
                    <p>{t("type")}: {document.type}</p>
                    <p>{t("date")}: {document.date}</p>
                    <p>
                      {t("status")}:{" "}
                      {document.status === "not_active" ? (
                        <Badge status="error" text={t("inactive")} />
                      ) : document.status === "under_development" ? (
                        <Badge status="processing" text={t("underDevelopment")} />
                      ) : (
                        <Badge status="success" text={t("active")} />
                      )}
                    </p>
                    <p>{t("language")}: {document.langue}</p>
                  </>
                }
              />
            </Card>
          </Col>
        ))}
      </Row>
      <Modal
        open={visible}
        title={editingDocument ? t("editDocument") : t("addDocument")}
        onCancel={handleCancel}
        onOk={editingDocument ? handleUpdate : handleCreate}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label={t("name")}
            rules={[{ required: true, message: t("enterSearchTerm") }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="type"
            label={t("type")}
            rules={[{ required: true, message: t("enterSearchTerm") }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="date"
            label={t("date")}
            rules={[
              { required: true, message: t("selectDate") },
            ]}
          >
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
          <Form.Item
            name="status"
            label={t("status")}
            rules={[
              { required: true, message: t("selectStatus") },
            ]}
          >
            <Select>
              <Option value="active">{t("active")}</Option>
              <Option value="not_active">{t("inactive")}</Option>
              <Option value="under_development">{t("underDevelopment")}</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="langue"
            label={t("language")}
            rules={[
              { required: true, message: t("selectLanguage") },
            ]}
          >
            <Select>
              <Option value="fr">FR</Option>
              <Option value="eng">ENG</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <style jsx>{`
        .card-meta-description p {
          margin: 0;
        }
      `}</style>
    </div>
  );
};

export default DocumentList;