import {
  CheckOutlined,
  DownOutlined,
  FileTextOutlined,
  LeftOutlined,
  RightOutlined,
  SearchOutlined,
  SendOutlined,
} from "@ant-design/icons";

import {
  Avatar,
  Button,
  Card,
  Divider,
  Empty,
  Form,
  Input,
  Layout,
  List,
  Space,
  Tabs,
  Typography,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../assets/styles/SliderStyles.css";
import axiosInstance from "../axiosInstance";
import HtmlModal from "../components/HtmlModal";
import customContexts from "../services/customContexts";
import SpinOverlay from "./SpinOverlay";

// Add this styled component

const { Title, Paragraph, Text } = Typography;
const { Content } = Layout;
const { TabPane } = Tabs;
const { Search } = Input;

const ResultsComponent = ({ results, lang }) => {
  const { t } = useTranslation();
  const [successStates, setSuccessStates] = useState(new Map());

  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisiblechat, setModalVisiblechat] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [htmlPages, setHtmlPages] = useState(null);
  const [formData, setFormData] = useState({ reference: "" });
  const { customColors, authenticated } = useContext(customContexts);
  const [isLoading, setIsLoading] = useState(false);
  const [initialResultsToShow, setInitialResultsToShow] = useState(15);
  const [remainingResults, setRemainingResults] = useState(0);
  const [questionId, setQuestionId] = useState(null);
  const [questionTexte, setQuestionTexte] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState(null);
  const [searchCounthistory, setSearchCounthistory] = useState(0);
  const [searchCountgrouped, setSearchCountgrouped] = useState(0);
  const [historyFound, setHistoryfound] = useState([]);
  const [groupedFound, setGroupedfound] = useState([]);

  const [visibleResults, setVisibleResults] = useState({
    chatgrouped: 15,
    history: 15,
  });

  useEffect(() => {
    setQuestionId(null);

    results.map((result) => {
      if (result.source === "chatgrouped") {
        setRemainingResults(result.data.result_qdrant.slice(15));

        setQuestionId(result.data.question.id);
        setQuestionTexte(result.data.question.texte);
      }
    });

    results.forEach((result) => {
      switch (result.source) {
        case "chatgrouped":
          result.data.result_qdrant.forEach((item) => {
            item.hits.forEach((info) => {
              if (info.La_loi.includes(searchTerm)) {
                // countchatgrouped++;
                // setGroupedfound((prev) => [...prev, info.La_loi]);
              }
            });
          });
          break;
        case "history":
          result.data.result_history.forEach((item) => {
            if (item.La_loi.includes(searchTerm)) {
              // counthistory++;
              // setHistoryfound((prev) => [...prev, item.La_loi]);
            }
          });
          break;
        default:
          break;
      }
    });
  }, [results]);
  const CARD_HEIGHT = 300; // Adjust this value as needed
  const renderResultItems = (source) => {
    const data =
      source === "chatgrouped"
        ? results.find((r) => r.source === "chatgrouped")?.data.result_qdrant ||
          []
        : results.find((r) => r.source === "history")?.data.result_history ||
          [];

    return data
      .slice(0, visibleResults[source])
      .map((item, index) => renderResultItem(item, source, index));
  };
  const showMore = (source) => {
    setVisibleResults((prevVisible) => {
      const data =
        source === "chatgrouped"
          ? results.find((r) => r.source === "chatgrouped")?.data
              .result_qdrant || []
          : results.find((r) => r.source === "history")?.data.result_history ||
            [];

      const newVisible = {
        ...prevVisible,
        [source]: prevVisible[source] + 15,
      };

      return newVisible;
    });
  };
  const renderShowMoreButton = (source) => {
    const data =
      source === "chatgrouped"
        ? results.find((r) => r.source === "chatgrouped")?.data.result_qdrant ||
          []
        : results.find((r) => r.source === "history")?.data.result_history ||
          [];

    if (visibleResults[source] < data.length) {
      return (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Button
            onClick={() => showMore(source)}
            icon={<DownOutlined />}
            size="large"
          >
            {t("showMore")}
          </Button>
        </div>
      );
    }
    return null;
  };

  const handleFeedback = async (
    section_label,
    hyperlink,
    section_texte,
    reference,
    source,
    questionId,
    itemId
  ) => {
    try {
      const feedbackData = {
        section_label,
        hyperlink,
        section_texte,
        reference,
        source,
        question_id: questionId,
      };
      console.log("Feedback Object:", feedbackData);

      const response = await axiosInstance.post("/feedback/save", feedbackData);
      console.log("Response:", response);
      setSuccessStates((prev) => new Map(prev).set(itemId, true));
      message.success(t("Feedbackaddedsuccessfully"));
    } catch (error) {
      console.error("Error submitting form:", error);
      message.error(t("Errorsubmittingfeedback"));
    }
  };

  const handleFormSubmit = async () => {
    try {
      const feedbackData = {
        reference: formData.reference,
        source: authenticated,
        question_id: questionId,
      };
      console.log("Feedback Object:", feedbackData);

      const response = await axiosInstance.post("/feedback/save", feedbackData);
      console.log("Response:", response);
      message.success(t("feedbackSuccess"));

      setFormData({ reference: "" });
      setModalVisible(false);
    } catch (error) {
      console.error("Error submitting form:", error);
      message.error(t("feedbackError"));
    }
  };

  const getfilename = async (hyperlinks) => {
    try {
      console.log("hyperlinks///", hyperlinks);
      const obj = {
        hyperlinks: hyperlinks,
        langue: lang === "en" ? "eng" : lang,
      };
      await axiosInstance
        .post("/hyperlinks/get_pages", obj)
        .then((response) => {
          console.log("Extracting pages of hyperlinks:", response.data);
          setModalVisiblechat(true);

          setHtmlPages(response.data);
        });
    } catch (error) {
      console.error("Error getting filename:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const showMoreResults = () => {
    setInitialResultsToShow(initialResultsToShow + 15);
    setRemainingResults(remainingResults.slice(initialResultsToShow));
  };

  const handleCardClick = (item) => {
    setIsLoading(true);

    setSelectedItem(item);
    setModalVisible(true);
  };
  const handleCardClickchat = (item) => {
    setIsLoading(true);

    setSelectedItem(item);
  };

  const closeModal = () => {
    setModalVisible(false);
  };
  const closeModalchat = () => {
    setModalVisiblechat(false);
  };
  const getModelColor = (model) => {
    if (["yazid", "ayman"].includes(authenticated)) {
      switch (model) {
        case "sentence-transformer":
          return "#2196F3"; // Blue
        case "gpt":
          return "#4CAF50"; // Green
        case "TFIDF":
          return "#FFC107"; // Amber
        default:
          return "#E0E0E0"; // Grey
      }
    }
    return "#2196F3"; // Default color if not authenticated as benoit or jack
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const CustomEmpty = () => (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />
  );

  const handleSearch = (value) => {
    setSearchTerm(value);
    let counthistory = 0;
    let countchatgrouped = 0;

    setGroupedfound([]);
    setHistoryfound([]);

    results.forEach((result) => {
      switch (result.source) {
        case "chatgrouped":
          result.data.result_qdrant.forEach((item) => {
            item.hits.forEach((info) => {
              if (info.La_loi.includes(value)) {
                countchatgrouped++;
                setGroupedfound((prev) => [...prev, info.La_loi]);
              }
            });
          });
          break;
        case "history":
          result.data.result_history.forEach((item) => {
            if (item.La_loi.includes(value)) {
              counthistory++;
              setHistoryfound((prev) => [...prev, item.La_loi]);
            }
          });
          break;
        default:
          break;
      }
    });

    setSearchCounthistory(counthistory);
    setSearchCountgrouped(countchatgrouped);
  };
  const cardHeight = 500; // Adjust this value as needed

  const renderResultItem = (item, source, index) => {
    const itemId = `${source}-${index}-${item.La_loi}`;
    const isSuccess = successStates.get(itemId);

    const cardStyle = {
      height: `${cardHeight}px`,
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
    };

    const contentStyle = {
      flex: 1,
      overflow: "auto",
    };

    if (source === "chatgrouped" && item.hits && item.hits.length > 1) {
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <RightOutlined />,
        prevArrow: <LeftOutlined />,
      };

      return (
        <List.Item>
          <Card
            title={
              <Space>
                <Avatar icon={<FileTextOutlined />} />
                <Text strong>
                  {t("reference")}: {item.group_id.replace("aymane", "")}
                </Text>
              </Space>
            }
            style={{ width: "100%", ...cardStyle }}
          >
            <div className="custom-slider">
              <Slider {...settings}>
                {item.hits.map((hit, index) => (
                  <div key={index}>
                    <Card
                      hoverable
                      style={{
                        borderColor: getModelColor(hit.model),
                        borderWidth: "2px",
                        marginBottom: "10px",

                        height: `${CARD_HEIGHT}px`,
                        overflow: "hidden",
                        display: "flex",
                        flexDirection: "column",
                      }}
                      onClick={() => {
                        handleCardClickchat(hit);

                        getfilename(hit.hyperlink);
                      }}
                      actions={
                        !["user", "user1"].includes(authenticated) && [
                          <Button
                            type={"default"}
                            icon={
                              isSuccess ? <CheckOutlined /> : <SendOutlined />
                            }
                            // F83E29
                            style={{
                              backgroundColor: isSuccess
                                ? "#52c41a"
                                : ["benoit", "jack"].includes(authenticated)
                                ? "#F83E29"
                                : undefined,
                              borderColor: isSuccess
                                ? "#52c41a"
                                : ["benoit", "jack"].includes(authenticated)
                                ? "white"
                                : undefined,
                              color: isSuccess
                                ? "white"
                                : ["benoit", "jack"].includes(authenticated)
                                ? "white"
                                : undefined,
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleFeedback(
                                hit.section_label,
                                hit.hyperlink,
                                hit.section_text,
                                hit.La_loi,
                                hit.model,
                                questionId,
                                itemId
                              );
                            }}
                            disabled={isSuccess}
                          >
                            {isSuccess
                              ? t("answerSubmitted")
                              : t("correctAnswer")}
                          </Button>,
                        ]
                      }
                    >
                      {/* <Skeleton loading={isLoading} active> */}
                      <Card.Meta
                        title={hit.titre}
                        description={
                          <Paragraph ellipsis={{ rows: 5 }}>
                            {hit.Paragraphe}
                          </Paragraph>
                        }
                      />
                      <Space direction="vertical" style={{ marginTop: 10 }}>
                        <Text type="secondary">
                          {t("reference")}: {hit.La_loi}
                        </Text>
                      </Space>
                      {/* </Skeleton> */}
                    </Card>
                  </div>
                ))}
              </Slider>
            </div>
          </Card>
        </List.Item>
      );
    } else if (
      source === "chatgrouped" &&
      item.hits &&
      item.hits.length === 1
    ) {
      // Render single item card (keep your existing card rendering logic here)
      return (
        <List.Item>
          <Card
            title={
              <Space>
                <Avatar icon={<FileTextOutlined />} />
                <Text strong>
                  {t("reference")}: {item.group_id.replace("aymane", "")}
                </Text>
              </Space>
            }
            style={{ width: "100%", ...cardStyle }}
          >
            {item.hits.map((item) => (
              <Card
                hoverable
                style={{
                  borderColor: getModelColor(item.model),
                  borderWidth: "2px",
                  marginBottom: "10px",
                  width: "100%",
                  height: `${CARD_HEIGHT}px`,
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                }}
                onClick={() => {
                  handleCardClickchat(item);

                  getfilename(item.hyperlink);
                }}
                actions={
                  !["user", "user1"].includes(authenticated) && [
                    <Button
                      type={"default"}
                      icon={isSuccess ? <CheckOutlined /> : <SendOutlined />}
                      style={{
                        backgroundColor: isSuccess
                          ? "#52c41a"
                          : ["benoit", "jack"].includes(authenticated)
                          ? "#F83E29"
                          : undefined,
                        borderColor: isSuccess
                          ? "#52c41a"
                          : ["benoit", "jack"].includes(authenticated)
                          ? "white"
                          : undefined,
                        color: isSuccess
                          ? "white"
                          : ["benoit", "jack"].includes(authenticated)
                          ? "white"
                          : undefined,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleFeedback(
                          item.section_label,
                          item.hyperlink,
                          item.section_text,
                          item.La_loi,
                          item.model,
                          questionId,
                          itemId
                        );
                      }}
                      disabled={isSuccess}
                    >
                      {isSuccess ? t("answerSubmitted") : t("correctAnswer")}
                    </Button>,
                  ]
                }
              >
                <div style={contentStyle}>
                  {/* <Skeleton loading={isLoading} active> */}
                  <Card.Meta
                    title={item.titre}
                    description={
                      <Paragraph ellipsis={{ rows: 5 }}>
                        {item.Paragraphe}
                      </Paragraph>
                    }
                  />
                  <Space direction="vertical" style={{ marginTop: 10 }}>
                    <Text type="secondary">Reference: {item.La_loi}</Text>
                  </Space>
                  {/* </Skeleton> */}
                </div>
              </Card>
            ))}
          </Card>
        </List.Item>
      );
    } else {
      return (
        <List.Item>
          <Card
            hoverable
            style={{
              borderColor: getModelColor(item.model),
              borderWidth: "2px",
              marginBottom: "10px",
              width: "100%",
              height: `250px`,
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
            onClick={() => {
              handleCardClickchat(item);

              getfilename(item.hyperlink);
            }}
          >
            <div style={contentStyle}>
              <Card.Meta
                title={
                  <Space>
                    <Avatar icon={<FileTextOutlined />} />
                    <Text strong>{item.titre}</Text>
                  </Space>
                }
                description={
                  <Paragraph ellipsis={{ rows: 5 }}>
                    {item.Paragraphe}
                  </Paragraph>
                }
              />
              <Space direction="vertical" style={{ marginTop: 10 }}>
                <Text type="secondary">Reference: {item.La_loi}</Text>
              </Space>
            </div>
          </Card>
        </List.Item>
      );
    }
  };

  return (
    <Content style={{ padding: "0 50px" }}>
      {["yazid"].includes(authenticated) && (
        <Search
          placeholder={t("enterSearchTerm")}
          enterButton={<Button icon={<SearchOutlined />}>{t("search")}</Button>}
          size="large"
          onSearch={handleSearch}
          style={{ marginBottom: 20 }}
        />
      )}

      {(historyFound.length > 0 || groupedFound.length > 0) && (
        <Card title={t("searchResult")} style={{ marginBottom: 20 }}>
          {historyFound.length > 0 && (
            <Paragraph>
              {t("term")} "{searchTerm}" {t("foundInHistory")}{" "}
              {searchCounthistory} {t("times")}
              <ul>
                {historyFound.map((loi, index) => (
                  <li key={index}>{loi}</li>
                ))}
              </ul>
            </Paragraph>
          )}
          {groupedFound.length > 0 && (
            <Paragraph>
              {t("term")} "{searchTerm}" {t("foundInChatgrouped")}{" "}
              {searchCountgrouped} {t("times")}
              <ul>
                {groupedFound.map((loi, index) => (
                  <li key={index}>{loi}</li>
                ))}
              </ul>
            </Paragraph>
          )}
        </Card>
      )}

      {/* <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <span>
              <FileTextOutlined />     {t("resultattrouver")}
            </span>
          }
          key="1"
        > */}

      <List
        grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}
        dataSource={renderResultItems("history")}
        renderItem={(item) => item}
        locale={{ emptyText: <CustomEmpty /> }}
      />
      {renderShowMoreButton("history")}

      {renderResultItems("history").length > 0 &&
        renderResultItems("chatgrouped").length > 0 && <Divider />}
      <List
        grid={{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 3 }}
        dataSource={renderResultItems("chatgrouped")}
        locale={{ emptyText: <CustomEmpty /> }}
        renderItem={(item) => item}
      />
      {renderShowMoreButton("chatgrouped")}

      {/* </TabPane>
        <TabPane
          tab={
            <span>
              <HistoryOutlined /> {t("reponsesenregistrer")}
            </span>
          }
          key="2"
        > */}

      {/* </TabPane>
      </Tabs> */}

      {questionId && !["user", "user1"].includes(authenticated) && (
        <Card title={t("addCorrectAnswer")} style={{ marginTop: 20 }}>
          <Form layout="vertical" onFinish={handleFormSubmit}>
            <Form.Item label={t("question")}>
              <Input disabled value={questionTexte} />
            </Form.Item>
            <Form.Item label={t("reference")}>
              <Input
                value={formData.reference}
                onChange={(e) =>
                  setFormData({ ...formData, reference: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t("add")}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      )}
      {selectedItem ? (
        isLoading ? null : (
          <HtmlModal
            visible={modalVisiblechat}
            onClose={closeModalchat}
            htmlFile={htmlPages}
            item={selectedItem}
            lang={lang}
          />
        )
      ) : null}

      <SpinOverlay isLoading={isLoading} />
    </Content>
  );
};

export default ResultsComponent;
