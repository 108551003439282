// RouteGuard.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";

// import authService from "../authService";
const RouteGuard = ({ element: Element, ...rest }) => {
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(false);
  // authService.setAuthToken(authService.getAuthToken());

  useEffect(() => {
    const checkJWT = async () => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          //   await axiosInstance
          //     .get("/api/protected-endpoint")
          //     .then((response) => {
          //       console.log(response);
          //       response.status === 200
          //         ? setAuthenticated(true)
          //         : setAuthenticated(false);
          //     });
          setAuthenticated(true);
        } else navigate("/login");
      } catch (error) {
        setAuthenticated(false);
      }
    };

    checkJWT();
  }, []);

  if (authenticated) {
    // Render the admin layout and the specified element
    return <MainLayout>{Element}</MainLayout>;
  } else {
    // Redirect to login if not authenticated
    navigate("/login");
    return null; // You can return null or a loading indicator here
  }
};

export default RouteGuard;
