import { ConfigProvider } from "antd";
import { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import QuestionsPage from "./components/QuestionsPage";
import i18n from "./i18n";
import Dashboard from "./pages/Dashboard";
import FaqPage from "./pages/FaqPage";
import LoginPage from "./pages/LoginPage";
import MFIhomepage from "./pages/MFIhomepage";
import NotFoundPage from "./pages/NotFoundPage";
import Order from "./pages/Order";
import Searchengine from "./pages/Searchengine";
import Settings from "./pages/Settings";
import VisitorsPage from "./pages/VisitorsPage";
import Test from "./pages/test";
import MainRoutes from "./services/MainRoutes";
import RouteGuard from "./services/RouteGuard";
import customContexts from "./services/customContexts";
// import ProfilePage from "./pages/ProfilePage";

function App() {
  const [colorchosen, setColorchosen] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [Lang, setLang] = useState("fr");

  useEffect(() => {
    const storedLang = localStorage.getItem("lang");
    if (storedLang) {
      setLang(storedLang);
      i18n.changeLanguage(storedLang);
    }
  }, []);

  const changeLanguage = (newLang) => {
    setLang(newLang);
    i18n.changeLanguage(newLang);
    localStorage.setItem("lang", newLang);
  };

  const [customColors, setCustomColors] = useState({
    primaryColor: colorchosen ? colorchosen : "#F4F4F1",
    royalBlue: {
      primary: "#001F3F",
      light: "#4E87A0",
    },
    red: {
      primary: "#FF0000",
      light: "#FF7373",
    },
    green: {
      primary: "#008000",
      light: "#7FFF7F",
    },
    pastelPurple: {
      primary: "#B39DDB",
      light: "#D1C4E9",
    },
    pastelYellow: {
      primary: "#FFF9C4",
      light: "#FFFDE7",
    },
    Button: {
      colorPrimary: "#FFF9C4",
    },
    blanccassé: "#F4F4F1",
  });

  const [appColors, setAppColors] = useState({
    Button: {
      colorPrimary: "#FFF9C4",
    },
  });

  return (
    <ConfigProvider
      theme={{
        fontFamily: "Mabry Pro",
        components: {
          Item: {
            colorPrimary: appColors.Button?.colorPrimary,
          },
        },
      }}
    >
      <customContexts.Provider
        value={{
          Lang,
          setLang: changeLanguage,
          customColors,
          setCustomColors,
          colorchosen,
          setColorchosen,
          authenticated,
          setAuthenticated,
          setAppColors,
          appColors,
        }}
      >
        <Router>
          <Routes>
            <Route path="/*" element={<MainRoutes />}>
              <Route path="questions" element={<QuestionsPage />} />
              {/* <Route path="profile" element={<ProfilePage />} /> */}
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="order" element={<Order />} />
              <Route path="order/:order" element={<Order />} />
              {/* <Route path="about" element={<ReferralSystemPage />} /> */}
              <Route path="settings" element={<Settings />} />
              <Route exact path="test" element={<Test />} />
              <Route path="faq" element={<FaqPage />} />
            </Route>
            <Route path="/" element={<RouteGuard />}>
              <Route index element={<MFIhomepage />} />
            </Route>

            <Route path="/searchen" element={<RouteGuard />}>
              <Route index element={<Searchengine />} />
            </Route>

            <Route path="/searchfr" element={<RouteGuard />}>
              <Route index element={<VisitorsPage />} />
            </Route>
            <Route exact path="/login" element={<LoginPage />} />

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
      </customContexts.Provider>
    </ConfigProvider>
  );
}

export default App;
